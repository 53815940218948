import ApiClient from "./apiClient";
import { QueriesApi } from "./apiTypes";



const apiAccessService = {

  getApiTokens:(queries:any) => ApiClient.get(`tokens/open-api/?page_size=${queries.pageSize}&page=${queries.page}`),
  createApiToken:(data:any) => ApiClient.post(`tokens/open-api/`, data),
  revokeApiToken:(id:string|number) => ApiClient.delete(`tokens/open-api/${id}/`),


  getOpenstackTokens:(queries:any) => ApiClient.get(`tokens/openstack/?page_size=${queries.pageSize}&page=${queries.page}`),
  createOpenstackToken:(data:any) => ApiClient.post(`tokens/openstack/`, data),
  revokeOpenstackToken:(id:string|number) => ApiClient.delete(`tokens/openstack/${id}/`),
  downloadYaml:(id:string|number) => ApiClient.get(`tokens/openstack/${id}/download_yaml/`),
  downloadOpenrc:(id:string|number) => ApiClient.get(`tokens/openstack/${id}/download_openrc/`),


  getS3Token:(queries:any) => ApiClient.get(`tokens/s3/?page_size=${queries.pageSize}&page=${queries.page}`),
  createS3Token:(data:any) => ApiClient.post(`tokens/s3/`, data),
  revokeS3Token:(id:string|number) => ApiClient.delete(`tokens/s3/${id}/`),
}

export default apiAccessService