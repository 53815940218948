import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  BackArrow,
  Input,
  Select,
  Button,
  TopProgress,
  LoaderSpinner,
  Typography,
  RadioButtonGroup
} from '../../../components'
import {
  Container,
  TitleBig,
  CreateContainer,
  EstimateContainer,
  EstimateTitle,
  EstimateSum,
  PerHour,
  StepsWrapper,
  StepContainer,
  InfoText,
  BodyContainer,
  TitleWrapper,
  Divider
} from './styles'
import * as yup from 'yup';
import { useFormik } from 'formik';
import { floatingIpService, objStorageService } from '../../../api'
import { useUi } from '../../../context/ui.context'
import { useTheme } from 'styled-components'
import {formatCurrency, formatCurrencyString } from '../../../utils/display-price'
import { useAuth } from '../../../context/user.context'
import { InfoContainer } from '../../../components/info-block/styles'
import { extractErrorMessage } from '../../../utils/handleErrorFile'


const CreateBucket:FC = () => {
  const navigate = useNavigate()
  const [t] = useTranslation()
  const {viewAlert} = useUi()
  const {user} = useAuth()
  const theme:any = useTheme()
  const [priceLoading, setPriceLoading] = useState<boolean>(false)
  const [price, setPrice] = useState<any>()
  const [priceMonth, setPriceMonth] = useState<any>()
  const [initialValues, setInitialValues ] = useState<any>({
    count:'10',
  })
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [visibility, setVisibility] = useState<any>('private')
  useEffect(() => {
    objStorageService.getPrices()
    .then((res) => {
      setPriceMonth(res.data?.find((item:any) => item?.currency === user.user.clients?.[0]?.currency || 'UAH')?.price)
      setPageLoading(false)
    })
    .catch((e:any) => {
       if (e.response && e.response.data) {
        const errorMessage = extractErrorMessage(e.response.data);
        viewAlert({
          severity: 'error',
          message: errorMessage,
        });
        setPageLoading(false)

      } else {
        viewAlert({
          severity: 'error',
          message: 'Network error or no response from the server',
        });
        setPageLoading(false)

      }
    })
  },[])
  const onSubmit = (values:any) => {
    setLoading(true)
    objStorageService.createBucket({
      "name": values.name,
      "access": visibility
  })
    .then((res:any) => {
      setLoadingComplete(true)
      setTimeout(() => {
        setLoading(false)
        setLoadingComplete(false)
        navigate(-1)
      },1000)
    })
    .catch((err) => {
      if(err.response?.data?.detail){
        viewAlert({
          severity:'error',
          message: err.response?.data?.detail
        })
      } else if(err?.response?.data) {
        setErrors(err.response.data)
      }
      setLoading(false)
    })
  }
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required(`${t("REQUIRED_FIELD")}`),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  // const getCurrentPrice = () => {
  //   let queries:any = {
  //     region: values['region'],
  //   }
  //   if(values['region']){
  //     setPriceLoading(true)
  //     floatingIpService.getEstimate(queries)
  //     .then((res) => {
  //       setPrice(formatCurrencyString(res.data.estimated_cost_display.split('-')[0]).hour)
  //       setPriceMonth(formatCurrencyString(res.data.estimated_cost_display.split('-')[1]).month)
  //       setPriceLoading(false)
  //     })
  //   }
  // }
  // useEffect(() => {
  //   getCurrentPrice()
  // },[values['region']])
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <CreateContainer>
      <TitleWrapper>
        <BackArrow
          onClick={() => navigate('/object-storage')}
          title={t("BACK_TO_OBJECT_STORAGE_LIST")}
        />
        <TitleBig>{t("CREATE_A_NEW_BUCKET")}</TitleBig>
      </TitleWrapper>
        <BodyContainer>
          <Container>
        <StepsWrapper>
          <StepContainer>
            <Typography variant='h2'>
              1.{t("ENTER_A_NAME")}
            </Typography>
            <Input
              fieldName='name'
              title={''}
              placeholder=''
              {...formikProps}
            />
          </StepContainer>
          <StepContainer>
            <Typography variant='h2'>
              2.{t("SET_YOUR_BUCKET_VISIBILITY")}
            </Typography>
            <InfoContainer style={{display:'flex', flexDirection:'column', gap:'12px', width:'100%', alignItems:'flex-start'}}>
              <InfoText>
                {t("VISIBILITY_INFO")}
              </InfoText>
              <RadioButtonGroup
                title={''}
                buttons={[
                  { value: 'private', title: t('PRIVATE') },
                  { value: 'public-read', title: t('PUBLIC') },
                ]}
                selectedButton={visibility}
                onChange={(e:any) => setVisibility(e)}
              />
            </InfoContainer>
          </StepContainer>
          <StepContainer>
            <Typography variant='h2'>
              3.{t("ESTIMATED_COST")}
            </Typography>
              <InfoText>
                {t("ESTIMATED_COST_INFO")}
              </InfoText>
          </StepContainer>
        </StepsWrapper>
        <div style={{display:'flex', marginTop:'40px'}}>
          <Button
            variant="primary"
            size="display"
            customStyles={{marginRight:"10px"}}
            onClick={handleSubmit}
            title={`+ ${t("CREATE")}`}
            loading={loading}
            loadingComplete={loadingComplete}
          />
          <Button
            variant="stroke"
            size="display"
            // customStyles={{width: '81px'}}
            onClick={() => navigate("/floating-ips")}
            title={t("CANCEL")}
          />
        </div>
        </Container>
      <EstimateContainer>
        <EstimateTitle>
          {t("ESTIMATED_COST")}
        </EstimateTitle>

        <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}}>
          <div style={{display:'flex', gap:'20px', width:'100%', justifyContent:"space-between", alignItems:'flex-end'}}>

            <div style={{display:'flex', alignItems:'flex-end', marginBottom:'2px'}}>
              <EstimateSum>
                {`${formatCurrency(priceMonth, user.user.clients?.[0]?.currency || 'UAH')}`}
              </EstimateSum>
              <PerHour>/ gb</PerHour>
            </div>
            <div style={{display:'flex', gap:'6px', alignItems:'flex-end'}}>
              <Input
                fieldName='count'
                customStyles={{width:'80px'}}
                title={''}
                placeholder=''
                {...formikProps}
              />
              <Typography variant='body' customStyles={{textTransform:"uppercase", marginBottom:'2px'}}>
                  GB
              </Typography>
            </div>
          </div>
          <Divider style={{margin:'14px 0px'}}/>
          <div style={{display:'flex', gap:'20px', width:'100%', justifyContent:"space-between", alignItems:'center'}}>
            <Typography variant='body' customStyles={{color:"#858A94"}}>
            Total
            </Typography>
            <div style={{display:'flex', alignItems:'flex-end'}}>
              <EstimateSum style={{fontSize:'26px'}} >
                {formatCurrency((priceMonth*values?.count), user.user.clients?.[0]?.currency || 'UAH')}
              </EstimateSum>
              <PerHour>/ {t("DAY")}</PerHour>
            </div>
          </div>
          {/* <div className=''></div> */}
          {/* <Divider/> */}
          {/* <div style={{display:'flex', alignItems:'flex-end'}}>
            <EstimateSum>
              {`${formatCurrency(priceMonth, user.user.clients?.[0]?.currency || 'UAH')}`}
            </EstimateSum>
            <PerHour>{t("MONTH")}</PerHour>
          </div> */}
        </div>

      </EstimateContainer>
      </BodyContainer>
    </CreateContainer>
  )
}

export default CreateBucket