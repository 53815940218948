import React, { FC, useEffect, useState } from "react";
import Dialog from "../../dialog";
import {
  Container,
  HeadWrapper,
  BodyModal,
  Icon,
} from './styles'
import { icons } from "../../../icons";
import Typography from "../../typography";
import Button from "../../button";
import { useTranslation } from "react-i18next";
import * as yup from 'yup';
import { useFormik } from 'formik';
import Input from "../../input";
import { HeadLine3 } from "../../typography/styled";
import Select from "../../select";

            
type ModalProps = {
  closeModalCancel:() => void;
  closeModal:(data:any) => void;
  loading?:boolean;
  loadingComplete?:boolean;
}  

const AddApiToken:FC<ModalProps> = (props) => {
  const {
    closeModal,
    closeModalCancel,
    loading,
    loadingComplete
  } = props
  const [t] = useTranslation()
  const [initialValues, setInitialValues] = useState<any>({
    name:'',
  })
  const onSubmit = (values:any) => {
    closeModal(values)
  }

  const regName = /^[a-zA-Z0-9\\!\"#$%&'()*+,\-./:;<=>?@\[\\\]\^_`{\|}~ ]+$/
  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name:yup.string()
      .when("type", {
        is: (type:any) => type !== 'S3',
        then: yup.string()
        .required(`${t("REQUIRED_FIELD")}`)
        .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
        .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      }),
      // name: yup.string()
      //   .required(`${t("REQUIRED_FIELD")}`)
      //   .matches(regName,`${t("ONLY_ENGLISH_LETTERS")}`)
      //   .max(60, `${t("STRING_VALIDATE_MAX")} 60 ${t("CHARACTERS")}`),
      type: yup.string(),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });
  
  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };
  return(
    <Container>
      <HeadWrapper>
        <HeadLine3 style={{marginLeft:'10px', marginTop:'10px'}}>
          {t("ADD_API_TOKEN")}
        </HeadLine3>
        <Icon onClick={closeModalCancel} src={icons.CloseIcon}/>
      </HeadWrapper>
      <BodyModal>
        {values?.type !== 'S3' && 
          <Input
            required
            title={t('NAME')}
            fieldName='name'
            placeholder={''}
            customStyles={{width:'360px'}}
            onBlur={handleBlur}
            {...formikProps}
          />
        }
        <Select
          required
          toolTip={false}
          data={localStorage.getItem('region_ocp_zone') === 'pl-1' ? [
            {value:"OpenStack",label:"OpenStack"},
            {value:"OpenAPI",label:"OpenAPI"},
          ] : [
            {value:"OpenStack",label:"OpenStack"},
            {value:"OpenAPI",label:"OpenAPI"},
            {value:"S3",label:"S3"},
          ]}
          selectedValue={values['type']}
          onChange={(e) => handleChange('type')(e)}
          title={t("TYPE")}
          customStyles={{width:'360px'}}
          errors={errors}
          test_id="token_type"
          fieldName={'type'}
        />
        <div style={{display:"flex", gap:'6px', marginTop:'16px'}}>
          <Button
            variant="stroke"
            size="display"
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={closeModalCancel}
            title={t("CANCEL")}
          />
          <Button
            variant="primary"
            size="display"
            loading={loading}
            loadingComplete={loadingComplete}
            customStyles={{ width: '177px', justifyContent:'center' }}
            onClick={handleSubmit}
            title={t("ADD")}
          />
        </div>
      </BodyModal>
    </Container>
  )
}

export default AddApiToken