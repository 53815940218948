import React, { FC, useEffect, useRef, useState } from "react";
import {
  Container,
  Header,
  TitleWrapper,
  ButtonsWrapper,
  Subtitle,
  Body
} from './styles'
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, ClipBoardWrapper, Link, ModalVariables, NotificationBlock, Table, TableSkeleton, Tabs, TopProgress, Typography } from "../../../../../components";
import { objStorageService } from "../../../../../api";
import IcUpload from './import1.svg'
import Skeleton from "react-loading-skeleton";
import { useTheme } from "styled-components";
import { useUi } from "../../../../../context/ui.context";
import IcFolder from './folder-ic.svg'
import IcFile from './document-ic.svg'
import IcDownload from './import.svg'
import moment from 'moment'
import { formatFileSizeRounded, formatFileSize } from "../../../../../utils/sizes";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import { icons } from "../../../../../icons";
import copy from 'copy-to-clipboard';
import { useAuth } from "../../../../../context/user.context";
import { extractErrorMessage } from "../../../../../utils/handleErrorFile";
interface FilesPage {
  files?:any
  updateFunc:() => void;
}

const Component:FC<FilesPage> = ({updateFunc}) => {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const inputRef:any = useRef()
  const {user} = useAuth()
  const {viewAlert, updateAlertProgress} = useUi()
  const [queryPath, setQueryPath] = useQueryParam('path', StringParam);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
  const [files, setFiles] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [path, setPath] = useState<any>('')
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const {bucketId} = useParams()
  const goUp = () => {
    // Убираем последний уровень директории
    const newPath = path.split('/').slice(0, -2).join('/') + '/';
    setPath(newPath);
    setQueryPath(newPath)
  };
  useEffect(() => {
    if(queryPath){
      setPath(queryPath)
    }
  },[queryPath])
  const getFiles = () => {
    //@ts-ignore
    objStorageService.getBucketFiles(bucketId, path||queryPath)
    .then((res) => {
      setPageLoading(false)
      setFiles([
        ...(path && path !== '/'
          ? [
              {
                file_name: (
                  <div onClick={goUp} style={{ display: 'flex',  gap: '6px', alignItems: 'center', cursor: 'pointer' }}>
                    <img src={IcFolder} alt="IcFolder" style={{ width: '16px' }} />
                    <Typography variant="body">...</Typography>
                  </div>
                ),
                hideProps:true
              },
            ]
          : []),
        ...res.data.folders.map((folder:any) => {
          return {
            file_name: (
              <div onClick={() => {
                setQueryPath(folder.Prefix)
                setPath(folder.Prefix)}} style={{display:'flex',  gap:'6px', alignItems:"center", cursor:'pointer'}}>
                <img src={IcFolder} alt="IcFolder" style={{width:"16px"}} />
                <Typography customStyles={{wordBreak:"break-all",}}variant="body">{folder.Prefix.replace(res.data.prefix, '').replaceAll("/",'')}</Typography>
              </div>
            ),
            hideProps:true

          }
        }),
        ...res.data.files.map((file:any) => {
          return {
            file_name: (
              <div style={{display:'flex', gap:'6px', alignItems:"flex-start"}}>
                <img src={IcFile} alt="IcFile" style={{width:"16px", marginTop:'2px'}} />
                <Typography customStyles={{wordBreak:"break-all",}} variant="body">{file.Key.replace(res.data.prefix, '')}</Typography>
              </div>
            ),
            id:`${bucketId}/files/${file.Key}`,
            clear_name:file.Key,
            name:file.Key,
            last_modified: moment(file.LastModified).fromNow(),
            size:formatFileSize(file.Size),
            download:(
              <div style={{cursor:'pointer'}} onClick={() => {
                if(bucketId){
                  objStorageService.getFileInfo(bucketId, file.Key)
                  .then((res:any) => {
                    window.location.href = res.data.Link
                  })
                  .catch((err:any) => {
                    viewAlert({
                      severity:'error',
                      message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
                    })
                  })
                }
              }}>
                <img src={IcDownload}/>
              </div>
            ),
            storage_class:file.StorageClass,
          }
        })
      ])
    })
    .catch((e) => {
      console.log('error', e);
    })
  }
  useEffect(() => {
    getFiles()
    const interval = setInterval(() => {
      getFiles()
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  },[path])
  const theme:any = useTheme()
  if(pageLoading) return (
    <Body>
      <TopProgress loading={pageLoading}/>
      <Skeleton
        baseColor={theme.palette[300]}
        highlightColor={theme.palette[20]}
        width={125} style={{marginBottom:"5px"}} height={30}
      />
      <TableSkeleton
        rows={10}
        columns={5}
        // columnsData={Columns}
      />
    </Body>
  )
  const ColumnsFiles = [
    {
      title:'Name',
      key:'file_name'
    },
    {
      title:t("SIZE"),
      key:'size',
    },
    {
      title:t("LAST_MODIFIED"),
      key:'last_modified'
    },
    {
      title:t("STORAGE_CLASS"),
      key:'storage_class'
    },
    {
      title:'',
      key:'download'
    },
    {
      title:'',
      key:'props'
    },
  ]
  
  const props = [
    {
      title:t("MORE_INFO"),
      id:'1',
      icon:icons.Info,
      onClick: (file:any) => {
        setSelectedFile(file)
        openModal('more-bucket-info')
      },
      divider:false
    },
    {
      title:t("VISIBILITY"),
      id:'2',
      icon:icons.EyeIc,
      onClick: (file:any) => {
        openModal('bucket-visibility')
        setSelectedFile(file)
      },
      divider:false
    },
    {
      title:t("OBJECT_METADATA"),
      id:'3',
      icon:icons.DocumentCodeIc,
      onClick: (file:any) => {
        setSelectedFile(file)
        openModal('bucket-metadata')
      },
      divider:false
    },
    {
      title:t("OBJECT_TAGS"),
      id:'4',
      icon:icons.CodeCircleIc,
      onClick: (file:any) => {
        setSelectedFile(file)
        openModal('bucket-file-tags')
      },
      divider:false
    },
    {
      title:t("EDIT_NAME"),
      id:'5',
      icon:icons.Edit,
      onClick: (file:any) => {
        setSelectedFile(file)
        setAlertTitle(`${t("EDIT_NAME")} «${file.clear_name}»`)
        openModal('rename')
      },
      divider:false
    },
    {
      title:t("GET_PUBLIC_LINK"),
      id:'6',
      icon:icons.ExportIc,
      onClick:async  (file:any) => {
        setSelectedFile(file)
        openModal('bucket-copy-filelink')
      },
      divider:false
    },
    {
      title:t("DELETE"),
      id:'7',
      onClick:(file:any) => {
        openModal('delete')
        setAlertTitle(t("FILE"))
        setAlertSubTitle([file])
        setSelectedFile(file)
      },
      icon:icons.DeleteIcon,
      divider:false
    },
  ]
  const handleUpload = async (e:any) => {
    const file = await e.target.files[0]
    if (file && bucketId) {
      await setLoadingUpload(true)
      // Создаем FormData и добавляем файл и дополнительные данные
      const formData = new FormData();
      formData.append("name", file.name);
      if(path){
        formData.append("path", path.slice(0, -1));
      }
      formData.append("file", file);

      try {
        // Отправка данных на сервер через API
        // const handledData = fileRequestHandler(values.source, data)

        //@ts-ignore
        const response = await objStorageService.uploadFile(bucketId, formData,updateAlertProgress, viewAlert({
          severity:'file',
          // timeout:0,
          message: '',
          canClose:false,
        }), user?.user?.clients?.[0]?.id);
        await setLoadingUpload(false)
      } catch (e:any) {
        console.error("Произошла ошибка:", e);
        if (e.response && e.response.data) {
          const errorMessage = extractErrorMessage(e.response.data);
          viewAlert({
            severity: 'error',
            message: errorMessage,
          });
        await setLoadingUpload(false)

        } else {
          viewAlert({
            severity: 'error',
            message: 'Network error or no response from the server',
          });
        await setLoadingUpload(false)

        }
      }
    }
    e?.target?.clearData()
  }
  const handleClick = () => {
    inputRef.current?.click();
  };
  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setModalType('')
    setActiveAnimation(false)
  }
  const deleteFile = async () => {
    setLoading(true);
    try {
      if (selectedFile) {
        await objStorageService.deleteBucket(selectedFile.id);
      }
      setLoadingComplete(true)
      updateFunc()
      getFiles()
      setTimeout(() => {
        setLoading(false)
        setLoadingComplete(false)
        setViewModal(false)
        setActiveAnimation(false)
        setModalType('')
      },1000)
    } catch (e:any) {
      // if (e?.response?.data.detail) {
      //   viewAlert({
      //     severity: 'error',
      //     message: e.response.data.detail,
      //   });
      if (e.response && e.response.data) {
        const errorMessage = extractErrorMessage(e.response.data);
        viewAlert({
          severity: 'error',
          message: errorMessage,
        });
        setLoading(false)
      } else {
        viewAlert({
          severity: 'error',
          message: 'Network error or no response from the server',
        });
        setLoading(false)
      }
      // setChecked([]);
    }
  }
  const closeModal = async (props?:any) => {
    let res:any;
    try {
      if(!bucketId) return;
      setLoading(true);
      switch (modalType) {
        case 'add-folder':
          res = await objStorageService.addFolder(bucketId, {name:`${path}${props?.name}`})
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'rename':
          res = await objStorageService.updateFile(bucketId, selectedFile.name, props)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'bucket-visibility':
          res = await objStorageService.updateFile(bucketId, selectedFile.name, props)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'bucket-metadata':
          res = await objStorageService.updateFile(bucketId, selectedFile.name, props)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'bucket-file-tags':
          res = await objStorageService.updateFile(bucketId, selectedFile.name, props)
          if (res?.data?.detail) {
            viewAlert({
              severity: 'info',
              message: res.data?.detail,
            });
          }
          break;
        case 'delete':
          deleteFile()
          break; 
      }
      if(modalType !== 'delete'){
        setLoadingComplete(true)
        updateFunc()
        setTimeout(() => {
          setLoading(false)
          setLoadingComplete(false)
          setViewModal(false)
          setActiveAnimation(false)
          setModalType('')
        },1000)
      }
    } catch (e: any) {
      console.log('e',e);
      if (e.response && e.response.data) {
        const errorMessage = extractErrorMessage(e.response.data);
        viewAlert({
          severity: 'error',
          message: errorMessage,
        });
      await setLoadingUpload(false)
      await setLoading(false)

      } else {
        viewAlert({
          severity: 'error',
          message: 'Network error or no response from the server',
        });
      await setLoadingUpload(false)
      await setLoading(false)

      }
    }
  }

  // if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <Header>
        <TitleWrapper>
          <Subtitle>{t("FILES_PAGE_DESCRIPTION")}</Subtitle>
          {/* <Link linkText={t("WHAT_AN_OBJECT")} link=""/> */}
        </TitleWrapper>
        <ButtonsWrapper>
          <Button
            variant="stroke"
            size="display"
            customStyles={{whiteSpace:'nowrap'}}
            onClick={() => openModal('add-folder')}
            title={`+ ${t("ADD_FOLDER")}`}
            // loading={loading}
            // loadingComplete={loadingComplete}
          />
          <input ref={inputRef} style={{display:'none'}} type='file' id="file-input" onChange={(e) => handleUpload(e)} />
          <Button
            variant="primary"
            size="display"
            customStyles={{whiteSpace:'nowrap'}}
            onClick={handleClick}
            title={`${t("UPLOAD")}`}
            icon={IcUpload}
            loading={loadingUpload}
            // loadingComplete={loadingComplete}
          />
        </ButtonsWrapper>
      </Header>
      <Body>
        {files?.length > 0 ?
          <Table
            columns={ColumnsFiles}
            rows={files}
            hideOther
            hideCheckbox
            settings={props}
            // paginationData={paginationData}
          />
        :
          <Typography variant="body">
            {t("NO_FILES_YET")}
          </Typography>
        }
      </Body>
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        instance={{...selectedFile, bucketId:bucketId, isFileNameBucket:true}}
        loading={loading}
        loadingComplete={loadingComplete}
        alertSubTitle={alertSubTitle}
      />
    </Container>
  )
}

export default Component