import React, { FC, useEffect, useState } from "react";
import {
  Container,
  Block,
  TextBlock,
  InfoText,
} from './styles'
import { Button, ClipBoardWrapper, Input, Link, ModalVariables, RadioButtonGroup, Table, TopProgress, Typography } from "../../../../../components";
import { useTranslation } from "react-i18next";
import { InfoContainer } from "../../../../../components/info-block/styles";
import { useNavigate, useParams } from "react-router-dom";
import { objStorageService } from "../../../../../api";
import LockIc from './lock.svg'
import { formatFileSize } from "../../../../../utils/sizes";
import { useUi } from "../../../../../context/ui.context";
import { JsonEditor } from 'json-edit-react'
import CopyIc from './copy.svg'
import { extractErrorMessage } from "../../../../../utils/handleErrorFile";
const Component:FC<any> = ({bucket}) => {
  const [t] = useTranslation()
  const [disabledVisibility, setDisabledVisibility] = useState<boolean>(false)
  const {viewAlert} = useUi()
  const navigate = useNavigate()
  const {bucketId} = useParams()
  const [pageLoading, setPageLoading] = useState(true)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>()
  const [alertSubTitle, setAlertSubTitle] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)
  

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setModalType('')
    setActiveAnimation(false)
  }
  const deleteBucket = async () => {
    setLoading(true);
    try {
      if (bucket) {
        await objStorageService.deleteBucket(bucket.id);
        // _updateImages();
        navigate('/object-storage/buckets')
      }
    } catch (e:any) {
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      // setChecked([]);
    } finally {
      setViewModal(false);
      setActiveAnimation(false);
      setLoading(false);
    }
  }
  const closeModal = async (props?:any) => {
    let res:any;
    try {
      setLoading(true);
      switch (modalType) {
        case 'delete':
          deleteBucket()
          break; 
      }
      setLoadingComplete(true)
      setTimeout(() => {
        setLoading(false)
        setLoadingComplete(false)
        setViewModal(false)
        setActiveAnimation(false)
        setModalType('')
      },1000)
      if(modalType !== 'delete'){
        setViewModal(false)
        setActiveAnimation(false)
      }
    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
    } finally {
      if(modalType !== 'delete'){
        setLoading(false);
      }
    }
  }


  useEffect(() => {
    objStorageService.getPolicy(bucket.id)
  },[])
  const [jsonState, setJsonState] = useState<any>({
    Policy: null
  });
  useEffect(() => {
    //@ts-ignore
    objStorageService.getPolicy(bucketId)
    .then((res) => {
      setJsonState(res.data.Policy)
      setPageLoading(false)
    })
    .catch((e:any) => {
      console.log('error', e);
      setPageLoading(false)
      if (e.response && e.response.data) {
        const errorMessage = extractErrorMessage(e.response.data);
        viewAlert({
          severity: 'error',
          message: errorMessage,
        });
        setPageLoading(false)

      } else {
        viewAlert({
          severity: 'error',
          message: 'Network error or no response from the server',
        });
        setPageLoading(false)

      }
    })
  },[])
  const onSubmit = () => {
    // setLoading(true);
    //@ts-ignore
    objStorageService.createPolicy(bucketId,jsonState)
    .then((res: any) => {
      // setLoadingComplete(true);
      setTimeout(() => {
        // setLoading(false);
        // setLoadingComplete(false);
        navigate(-1);
      }, 1000);
    })
    .catch((e:any) => {
      if (e.response && e.response.data) {
        const errorMessage = extractErrorMessage(e.response.data);
        viewAlert({
          severity: 'error',
          message: errorMessage,
        });
        setPageLoading(false)

      } else {
        viewAlert({
          severity: 'error',
          message: 'Network error or no response from the server',
        });
        setPageLoading(false)

      }
      // setLoading(false);
    });
  };
  if(pageLoading) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <Block>
        <Typography variant="h1">
          {t("BUCKET_INFO")}
        </Typography>
        <div>
          <Table
            columns={[
              {
                title:t("VISIBILITY"),
                key:'visibility'
              },
              // {
              //   title:t("USE_CASE"),
              //   key:'use_case',
              // },
              {
                title:t("BUCKET_ID"),
                key:'name',
              },
              {
                title:t("SIZE"),
                key:'size'
              },
            ]}
            rows={[
              {
                visibility:(
                  <div style={{display:'flex', gap:'6px'}}>
                    {bucket.access === 'private' ? 
                      <img src={LockIc}/>
                    : null
                    }
                    <Typography variant='body'>
                      {bucket.access}
                    </Typography>
                  </div>
                ),
                use_case:'',
                name:<ClipBoardWrapper toCopy={bucket.name} customStyles={{display:'inline-flex', gap:'6px'}}>{bucket.name} <img src={CopyIc}/></ClipBoardWrapper>,
                size:formatFileSize(bucket.usage),
              }
            ]}
            hideOther
            hideCheckbox
            // paginationData={paginationData}
          />
          <Table
            columns={[
              {
                title:t("NUMBER_OF_OBJECTS"),
                key:'count'
              },
              {
                title:t("BUCKET_ENDPOINT"),
                key:'endpoint',
              },
            ]}
            rows={[
              {
                count:bucket.num_objects,
                endpoint:<ClipBoardWrapper toCopy='https://s3.ua-central-1.ocplanet.cloud/' customStyles={{display:'inline-flex', gap:'6px'}}>
                  https://s3.ua-central-1.ocplanet.cloud/
                  <img src={CopyIc}/>
                </ClipBoardWrapper>,
              },

            ]}
            hideOther
            hideCheckbox
            // paginationData={paginationData}
          />
        </div>
      </Block>
      {/* <Block>
        <Typography variant="h1">
          {t("TAGS")}
        </Typography>
        <InfoContainer style={{display:'flex', flexDirection:'column', gap:'10px'}}>
          <Typography variant="body">
            {t("TAGS_DESC")}
          </Typography>
            <Input
              fieldName='name'
              title={''}
              placeholder=''
              // {...formikProps}
            />
        </InfoContainer>
      </Block> */}
      <Block>
        <Typography variant="h1">
          {t("BUCKET_POLICY")}
        </Typography>
        <TextBlock>
          <div style={{display:'flex', flexDirection:'column', gap:'4px'}}>
            <InfoText>{t("BUCKET_POLICIES_INFO")}</InfoText>
            {/* <InfoText style={{display:'inline-flex', gap:'4px'}}>
              {t("BUCKET_POLICIES_INFO1")}
              <span>
                <Link link='' linkText={t("BUCKET_POLICIES_INFO_LINK")}/>
              </span>
              {t("BUCKET_POLICIES_INFO2")}
            </InfoText> */}
          </div>
          <Button
            variant="primary"
            size="display"
            onClick={onSubmit}
            title={`${t("SAVE")}`}
          />
        </TextBlock>
        <JsonEditor
          data={ jsonState }
          setData={ setJsonState }
        />
      </Block>
      <Block>
        <Typography variant="h1">
          {t("BUCKET_VISIBILITY")}
        </Typography>
        <InfoContainer style={{display:'flex', flexDirection:'column', gap:'12px', width:'100%', alignItems:'flex-start'}}>
          <InfoText>
            {t("VISIBILITY_INFO")}
          </InfoText>
          {/* Bucket visibility updated */}
          <RadioButtonGroup
            title={''}
            buttons={[
              { value: 'private', title: t('PRIVATE') },
              { value: 'public-read', title: t('PUBLIC') },
            ]}
            selectedButton={bucket.access}
            disabled={disabledVisibility}
            onChange={async(e:any) => {
              try{
                await setDisabledVisibility(true)
                const res = await objStorageService.editBucket(bucket.id, {access: e })
                viewAlert({
                  severity:"information",
                  message:`${t("BUCKET_VISIBILITY_UPDATED")}`
                })
                // setVisibility(e)
                await setDisabledVisibility(false)
              } catch (err:any){
                viewAlert({
                  severity:"error",
                  message:err?.response?.data?.detail || `${t("ERROR_OCCURRED")}`
                })
                await setDisabledVisibility(false)
              }

            }}
          />
        </InfoContainer>
      </Block>
      <Block>
        <Typography variant="h1">
          {t("DELETE_BUCKET")}
        </Typography>
        <TextBlock>
          <InfoText>
            {t("DELETE_BUCKET_INFO")}
          </InfoText>
          <Button
            variant="primary"
            size="display"
            onClick={() => {
              openModal('delete')
              setAlertTitle(t("BUCKET"))
              setAlertSubTitle([bucket])
            }}
            title={`${t("DELETE_BUCKET")}`}
          />
        </TextBlock>
      </Block>

      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        alertTitle={alertTitle}
        instance={bucket}
        // loading={loading}
        // loadingComplete={loadingComplete}
        alertSubTitle={alertSubTitle}
      />
    </Container>
  )
}

export default Component