import React, {FC, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { Button, InfoBlock, Label, ModalVariables, Table, TopProgress, Typography } from '../../../../components'
import { BodyDefault } from '../../../../components/typography/styled'
import { useUi } from '../../../../context/ui.context'
import { icons } from '../../../../icons'
import {
  Container,
  CopyIcon,
  TokenBody
} from './styles'
import { ClipBoardWrapper } from '../../../../components'
import {apiAccessService, authService} from '../../../../api'
import { timeConverterMMDDYYHHMM } from '../../../../utils/dates'
import rootConfig from '../../../../config'
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';


const ApiAccessPage:FC<any> = () => {
  const [t] = useTranslation()
  const [openApiTokens, setOpenApiTokens] = useState<any[]>([])
  const [openstackTokens, setOpenstackTokens] = useState<any[]>([])
  const [s3Tokens, setS3Tokens] = useState<any[]>([])


  const [pageLoading, setPageLoading] = useState<boolean>(true)
  const [pageLoadingS3, setPageLoadingS3] = useState<boolean>(true)
  const [pageLoadingOpenstack, setPageLoadingOpenstack] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)


  const [viewModal, setViewModal] = useState<boolean>(false)
  const [activeAnimation, setActiveAnimation] = useState<boolean>(false)
  const [modalType, setModalType] = useState<any>('')
  const [selectedToken, setSelectedToken] = useState<any>('')
  const [alertTitle, setAlertTitle] = useState<any>('')
  const [alertSubTitle, setAlertSubTitle] = useState<any>()


  const [pageOpenApi, setPageOpenApi] = useQueryParam('page_openapi', NumberParam);
  const [pageSizeOpenApi, setPageSizeOpenApi] = useQueryParam('page_size_openapi', NumberParam)
  const [paginationDataOpenApi, setPaginationDataOpenApi] = useState('')


  const [pageOpenstack, setPageOpenstack] = useQueryParam('page_openstack', NumberParam);
  const [pageSizeOpenstack, setPageSizeOpenstack] = useQueryParam('page_size_openstack', NumberParam)
  const [paginationDataOpenstack, setPaginationDataOpenstack] = useState('')


  const [pageS3, setPageS3] = useQueryParam('page_s3', NumberParam);
  const [pageSizeS3, setPageSizeS3] = useQueryParam('page_size_s3', NumberParam)
  const [paginationDataS3, setPaginationDataS3] = useState('')
  const {viewAlert} = useUi()

  const getS3Tokens = async () => {
    try {
      const res = await apiAccessService.getS3Token({
        page:pageS3||1,
        pageSize:pageSizeS3||10,
      })
      await setPaginationDataS3(res.data)
      await setS3Tokens(res.data.objects.map((token:any) => {
        return {
          ...token,
          name:token?.access_key,
          type:'s3',
          secret_key:(
            <ClipBoardWrapper toCopy={token?.secret_key}>
              <div style={{display:'flex', gap:'6px', alignItems:'center', width:'20vw'}}>
                <TokenBody>
                  {token?.secret_key}
                </TokenBody>
                <CopyIcon style={{ marginLeft: '6px', cursor:'pointer' }} src={icons.Copy} />
              </div>
            </ClipBoardWrapper>
          ),
          access_key:(
            <ClipBoardWrapper toCopy={token?.access_key}>
              <div style={{display:'flex', gap:'6px', alignItems:'center', width:'20vw'}}>
                <TokenBody>
                  {token?.access_key}
                </TokenBody>
                <CopyIcon style={{ marginLeft: '6px', cursor:'pointer' }} src={icons.Copy} />
              </div>
            </ClipBoardWrapper>
          ),
          dropOptions: [
            {
              title:t("DELETE"),
              id:'4',
              onClick:(token:any) => {
                openModal('delete')
                setAlertTitle(token?.name)
                setAlertSubTitle([token?.name])
                setSelectedToken(token)
              },
              icon:icons.DeleteIcon,
              divider:false
            },
          ]
        }
      }))
      await setPageLoadingS3(false)
    } catch (err:any) {
      viewAlert({
        severity: 'error',
        message: err?.response?.data?.detail || t("ERROR_OCCURRED")
      })
    }
  }
  const getOpenstackTokens = async () => {
    try {
      const resOpenstack = await apiAccessService.getOpenstackTokens({
        page:pageOpenstack||1,
        pageSize:pageSizeOpenstack||10,
      })
      await setPaginationDataOpenstack(resOpenstack?.data)
      await setOpenstackTokens(resOpenstack?.data.objects.map((token:any) => {
        return {
          ...token,
          name: token.name,
          tokenClear:token?.token,
          type:'openstack',
          created_at:timeConverterMMDDYYHHMM(token.created_at),
          app_id: token?.app_id ? (
            <ClipBoardWrapper toCopy={token?.app_id}>
              <div style={{display:'flex', gap:'6px', alignItems:'center', width:'20vw'}}>
                <TokenBody>
                  {token?.app_id}
                </TokenBody>
                <CopyIcon style={{ marginLeft: '6px', cursor:'pointer' }} src={icons.Copy} />
              </div>
            </ClipBoardWrapper>
          ) :
          (<Label 
            title={'-'}
            tooltipText={t("OPENSTACK_TOKEN_INFO")}
          />),
          token: token?.app_secret ? (
            <ClipBoardWrapper toCopy={token?.app_secret}>
              <div style={{display:'flex', gap:'6px', alignItems:'center', width:'20vw'}}>
                <TokenBody>
                  {token?.app_secret}
                </TokenBody>
                <CopyIcon style={{ marginLeft: '6px', cursor:'pointer' }} src={icons.Copy} />
              </div>
            </ClipBoardWrapper>
          ) :
          (<Label 
            title={'-'}
            tooltipText={t("OPENSTACK_TOKEN_INFO")}
          />),
          dropOptions:[
            {
              title:t("DOWNLOAD_YAML"),
              id:'4',
              onClick:async (token:any) => {
                const res = await authService.generateOtpToken()
                const url = `${rootConfig.apiUrl}api/tokens/openstack/${token.id}/download_yaml/?fleio-token=${res.data.otp_token}`
                window.location.href = url
              },
              icon:icons.Download,
              divider:false
            },
            {
              title:t("DOWNLOAD_OPENRC"),
              id:'3',
              onClick:async (token:any) => {
                const res = await authService.generateOtpToken()
                const url = `${rootConfig.apiUrl}api/tokens/openstack/${token.id}/download_openrc/?fleio-token=${res.data.otp_token}`
                window.location.href = url
              },
              icon:icons.Download,
              divider:false
            },
            {
              title:t("DELETE"),
              id:'4',
              onClick:(token:any) => {
                openModal('delete')
                setAlertTitle(token?.name)
                setAlertSubTitle([token?.name])
                setSelectedToken(token)
              },
              icon:icons.DeleteIcon,
              divider:false
            },
          ]
        }
      }))
      await setPageLoadingOpenstack(false)
    } catch (err:any) {
      viewAlert({
        severity: 'error',
        message: err?.response?.data?.detail || t("ERROR_OCCURRED")
      })
    }
  }
  const getTokens = async () => {
    try {
      const resApi = await apiAccessService.getApiTokens({
        page:pageOpenApi||1,
        pageSize:pageSizeOpenApi||10,
      })
      setPaginationDataOpenApi(resApi?.data)
      setOpenApiTokens(resApi.data.objects.map((token:any) => {
        return {
          ...token,
          name: token?.name,
          type:'openapi',
          tokenClear:token?.token,
          created_at:timeConverterMMDDYYHHMM(token.created_at),
          token: (
            <ClipBoardWrapper toCopy={token?.token}>
              <div style={{display:'flex', gap:'6px', alignItems:'center', width:'20vw'}}>
                <TokenBody>
                  {token?.token}
                </TokenBody>
                <CopyIcon style={{ marginLeft: '6px', cursor:'pointer' }} src={icons.Copy} />
              </div>
            </ClipBoardWrapper>
          ),
          dropOptions: [
            {
              title:t("DELETE"),
              id:'4',
              onClick:(token:any) => {
                openModal('delete')
                setAlertTitle(token?.name)
                setAlertSubTitle([token?.name])
                setSelectedToken(token)
              },
              icon:icons.DeleteIcon,
              divider:false
            },
          ]
        }
      }))
      setPageLoading(false)
    } catch (err:any) {
      viewAlert({
        severity: 'error',
        message: err?.response?.data?.detail || t("ERROR_OCCURRED")
      })
    }
  }

  useEffect(() => {
    getTokens()
    if(localStorage.getItem('region_ocp_zone') !== 'pl-1'){
      getS3Tokens()
    }
    getOpenstackTokens()
    // const interval = setInterval(() => {
    //   getTokens()
    //   getS3Tokens()
    //   getOpenstackTokens()
    // },10000)
    // return () => {
    //   clearInterval(interval)
    // }
  },[])

  useEffect(() => {
    getTokens()
  },[pageOpenApi, pageSizeOpenApi])
  useEffect(() => {
    getOpenstackTokens()
  },[pageOpenstack, pageSizeOpenstack])
  useEffect(() => {
    if(localStorage.getItem('region_ocp_zone') !== 'pl-1'){
      getS3Tokens()
    }
  },[pageS3, pageSizeS3])

  const openModal = (modal:any) => {
    setModalType(modal)
    setViewModal(true)
    setActiveAnimation(true)
  }
  const closeModalCancel = () => {
    setViewModal(false)
    setActiveAnimation(false)
  }

  const closeModal = async (values:any) => {
    let res:any;
    try {
      setLoading(true);
      switch (modalType) {
        case 'add-api-token':
          if(values?.type === 'OpenStack'){
            res = await apiAccessService.createOpenstackToken(values)
          } else if (values?.type === 'OpenAPI'){
            res = await apiAccessService.createApiToken(values)
          } else {
            res = await apiAccessService.createS3Token(values)
            if(localStorage.getItem('region_ocp_zone') !== 'pl-1'){
              await getS3Tokens()
            }
          }
          await getTokens()
          await getOpenstackTokens()
          await setLoadingComplete(true)
          await setTimeout(() => {
            setLoading(false)
            setLoadingComplete(false)
            setModalType('')
            setViewModal(false)
            setActiveAnimation(false)
          },1000)
          break;
        case 'delete':
          if(selectedToken?.type === 'openstack'){
            res = await apiAccessService.revokeOpenstackToken(selectedToken.id)
          } else if (selectedToken?.type === 'openapi'){
            res = await apiAccessService.revokeApiToken(selectedToken.tokenClear)
          } else {
            res = await apiAccessService.revokeS3Token(selectedToken.name)
            if(localStorage.getItem('region_ocp_zone') !== 'pl-1'){
              await getS3Tokens()
            }
          }
          // res = await apiAccessService.revokeToken(selectedToken.id);
          await getTokens()
          await getOpenstackTokens()
          await setLoadingComplete(true)
          await setTimeout(() => {
            setLoading(false)
            setLoadingComplete(false)
            setModalType('')
            setViewModal(false)
            setActiveAnimation(false)
          },1000)
          break;
      }
    } catch (e: any) {
      console.log('e',e);
      if (e?.response?.data.detail) {
        viewAlert({
          severity: 'error',
          message: e.response.data.detail,
        });
      }
      setLoading(false);

    } 
  }

  const Columns = [
    {
      title:t('NAME'),
      key:'name'
    },
    {
      title:"access_key",
      key:'app_id'
    },
    {
      title:'secret_key',
      key:'token'
    },
    {
      title:t("CREATED_AT"),
      key:'created_at'
    },
    {
      title:'',
      key:'props'
    }
  ]
  const openApiColumns = [
    {
      title:t('NAME'),
      key:'name'
    },
    {
      title:t("TOKEN"),
      key:'token'
    },
    {
      title:t("CREATED_AT"),
      key:'created_at'
    },
    {
      title:'',
      key:'props'
    }
  ]
  const s3Columns = [
    {
      title:'access_key',
      key:'access_key'
    },
    {
      title:'secret_key',
      key:'secret_key'
    },
    {
      title:'',
      key:'props'
    }
  ]

  if(pageLoading  || pageLoadingOpenstack) return <TopProgress loading={pageLoading}/>
  return(
    <Container>
      <div style={{display:'flex', justifyContent:'space-between', alignItems:'flex-start'}}>
        {/* <Typography variant="h3">
          {t("TOKENS")}
        </Typography> */}
        <Button
          title={t("ADD_API_TOKEN")}
          size='display'
          variant='primary'
          customStyles={{marginBottom:'20px'}}
          onClick={() => {
            openModal('add-api-token')
          }}
        />
      </div>
        <InfoBlock
          dropped
          noTitles
          //@ts-ignore
          data={[]}
          customDropped={
            <Table
              hideCheckbox
              // hideOther
              paginationData={paginationDataOpenApi}
              columns={openApiColumns}
              rows={openApiTokens}
              customSetPage={setPageOpenApi}
              customSetPageSize={setPageSizeOpenApi}
              customPageSize={pageSizeOpenApi}
            />
          }
          title={<div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:"center"}}>
            <Typography variant="h3">
            Open Api Tokens
            </Typography>
          </div>}
          customStyles={{flex:'auto 1', marginRight:'10px'}}
        />
        <InfoBlock
          dropped
          noTitles
          //@ts-ignore
          data={[]}
          customDropped={
            <Table
              hideCheckbox
              // paginationData={}
              // hideOther
              paginationData={paginationDataOpenstack}
              columns={Columns}
              rows={openstackTokens}
              customSetPage={setPageOpenstack}
              customSetPageSize={setPageSizeOpenstack}
              customPageSize={pageSizeOpenstack}
            />
          }
          title={<div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:"center"}}>
            <Typography variant="h3">
            Openstack Tokens
            </Typography>

          </div>}
          customStyles={{flex:'auto 1', marginRight:'10px'}}
        />
        {localStorage.getItem('region_ocp_zone') === 'pl-1' || s3Tokens?.length === 0 || !s3Tokens ? null :
          <InfoBlock
            dropped
            noTitles
            //@ts-ignore
            data={[]}
            customDropped={
              <Table
                hideCheckbox
                // hideOther
                paginationData={paginationDataS3}
                columns={s3Columns}
                rows={s3Tokens}
                customSetPage={setPageS3}
                customSetPageSize={setPageSizeS3}
                customPageSize={pageSizeS3}
              />
            }
            title={<div style={{display:'flex', width:'100%', justifyContent:'space-between', alignItems:"center"}}>
              <Typography variant="h3">
              S3 Tokens
              </Typography>

            </div>}
            customStyles={{flex:'auto 1', marginRight:'10px'}}
          />
        }
      <ModalVariables
        modalType={modalType}
        viewModal={viewModal}
        activeAnimation={activeAnimation}
        closeModal={closeModal}
        closeModalCancel={closeModalCancel}
        loadingComplete={loadingComplete}
        alertTitle={alertTitle}
        alertSubTitle={alertSubTitle}
        loading={loading}
      />
    </Container>
  )
}

export default ApiAccessPage