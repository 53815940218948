import styled from "styled-components";
import { BodyDefault, HeadLine1, HeadLine3, VerySmall } from "../../../components/typography/styled";

export const Container = styled.div`
  display:flex;
  justify-content: space-between;
  flex-direction: column;
  width:100%;
`
export const BodyContainer = styled.div`
  display:flex;
  gap:30px;
  justify-content: space-between;
  align-items: flex-start;
  width:100%;
  box-sizing: border-box;
  padding: 0px 30px 50px 30px;

`
export const CreateContainer = styled.div`
  justify-content: space-between;
  flex-direction: column;
  display: inline-flex;
  align-items: flex-start;
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
  padding: 10px 30px;

`
export const TitleBig = styled(HeadLine1)`
  color:${({theme}) => theme.palette[850]};
`

export const EstimateContainer = styled.div`
  box-sizing: border-box;
  padding: 15px;
  width: 400px;
  background: ${({theme}) => theme.palette[200]};
  border-radius: 8px;
  margin-top: 20px;
`

export const EstimateTitle = styled(HeadLine3)`
  color:${({theme}) => theme.palette[850]};
`
export const EstimateSum = styled(BodyDefault)`
  color:${({theme}) => theme.palette[850]};
`
export const PerHour = styled(VerySmall)`
  color:${({theme}) => theme.palette[650]};
  margin-left:2px;
  margin-bottom:2px;
`
export const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap:16px;
  padding-top:16px;
`

export const StepContainer = styled(StepsWrapper)`
  padding-top:0px;
  gap:10px;
`

export const InfoText = styled(BodyDefault)`
  color:${({theme}) => theme.grey[700]};
`
export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({theme}) => theme.grey[350]};

`