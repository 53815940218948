import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  BackArrow,
  Input,
  Select,
  Button,
  TopProgress,
  LoaderSpinner,
  Typography,
  RadioButtonGroup,
  CheckBox,
  Link,
} from "../../../components";
import {
  Container,
  TitleBig,
  CreateContainer,
  StepsWrapper,
  StepContainer,
  InfoText,
  BodyContainer,
  TitleWrapper,
  CheckboxContainer,
  CheckboxWrapper,
  DarkForm,
} from "./styles";
import * as yup from "yup";
import { useFormik } from "formik";
import { objStorageService } from "../../../api";
import { useUi } from "../../../context/ui.context";
import { useTheme } from "styled-components";
import { useAuth } from "../../../context/user.context";
import JSONInput from "react-json-editor-ajrm";
//@ts-ignore
import locale from "react-json-editor-ajrm/locale/en";
import { InfoContainer } from "../../../components/info-block/styles";
import { icons } from "../../../icons";
//@ts-ignore
// import { JsonEditor as Editor } from 'jsoneditor-react';
import { JsonEditor } from 'json-edit-react'

const CreateBucket: FC = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { viewAlert } = useUi();
  const { user } = useAuth();
  const theme: any = useTheme();
  const {bucketId} = useParams()
  const [initialValues, setInitialValues] = useState<any>({
    description: "",
  });
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false);

  const [statement, setStatement] = useState<any>('1')
  const [jsonState, setJsonState] = useState<any>({
    "Policy": {
      "Version": "2012-10-17",
      "Statement": [{
        "Effect": "Allow",
        "Principal": {"AWS": ["arn:aws:iam::usfolks:user/fred:subuser"]},
        "Action": "s3:PutObjectAcl",
        "Resource": [
          "arn:aws:s3:::testbucket2709/*"
        ]
      }]
    }
  });
  useEffect(() => {
    //@ts-ignore
    objStorageService.getPolicy(bucketId)
    .then((res) => {
      setJsonState(res.data.Policy)
    })
    .catch((err) => {
      console.log('error', err);
    })
  },[])
  const onSubmit = (values: any) => {
    setLoading(true);
    //@ts-ignore
    objStorageService.createPolicy(bucketId,jsonState)
    .then((res: any) => {
      setLoadingComplete(true);
      setTimeout(() => {
        setLoading(false);
        setLoadingComplete(false);
        navigate(-1);
      }, 1000);
    })
    .catch((err) => {
      if (err.response?.data?.detail) {
        viewAlert({
          severity: "error",
          message: err.response?.data?.detail,
        });
      } else if (err?.response?.data) {
        setErrors(err.response.data);
      }
      setLoading(false);
    });
  };
  const {
    values,
    errors,
    touched,
    setErrors,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      // name: yup.string().required(`${t("REQUIRED_FIELD")}`),
      // conditions: yup.array().of(
      //   yup.object().shape({
      //     condition: yup.string().required(`${t("REQUIRED_FIELD")}`),
      //     key: yup.string().required(`${t("REQUIRED_FIELD")}`),
      //     value: yup.string().required(`${t("REQUIRED_FIELD")}`),
      //   })
      // ),
    }),
    initialValues: {
      ...initialValues,
    },
    onSubmit,
  });

  const formikProps = {
    errors,
    values,
    touched,
    handleChange,
    handleBlur,
  };

  const [conditionsArr, setConditionsArr] = useState<any[]>([]);

  const handleInputChange = (index: any, field: any, value: any) => {
    const updatedData = [...conditionsArr];
    updatedData[index][field] = value;
    setConditionsArr(updatedData);
    setFieldValue("conditions", updatedData);
  };
  const addNewItem = (instance?: any) => {
    setConditionsArr((prev: any) => {
      setFieldValue("conditions", [
        ...prev,
        {
          condition: "",
          key: "",
          value: "1",
        },
      ]);
      return [
        ...prev,
        {
          condition: "",
          key: "",
          value: "1",
        },
      ];
    });
  };
  const removeItem = (index: any) => {
    const updatedData = [...conditionsArr];
    updatedData.splice(index, 1);
    setConditionsArr(updatedData);
    setFieldValue("conditions", updatedData);
  };

  if (pageLoading) return <TopProgress loading={pageLoading} />;
  return (
    <CreateContainer>
      <TitleWrapper>
        <BackArrow
          onClick={() => navigate(-1)}
          title={t("BACK_TO_OBJECT_STORAGE_LIST")}
        />
        <TitleBig>{t("CREATE_A_NEW_BUCKET_POLICY")}</TitleBig>
      </TitleWrapper>
      <BodyContainer>
        <Container>
          <StepsWrapper>
            <StepContainer style={{ maxWidth: "545px" }}>
              <Typography variant="h2">1.{t("ENTER_A_NAME")}</Typography>
              <Input
                fieldName="name"
                title={""}
                placeholder=""
                {...formikProps}
              />
            </StepContainer>
            {/* <StepContainer>
              <Typography variant="h2">2.{t("ADD_STATEMENT")}</Typography>
              <CheckboxWrapper>
                <CheckboxContainer
                  onClick={() => setStatement("1")}
                  active={statement === "1"}
                >
                  <CheckBox
                    title={t("POLICY_GENERATOR")}
                    value={statement === "1"}
                    onChange={() => setStatement("1")}
                  />
                  <InfoText>{t("POLICY_GENERATOR_INFO")}</InfoText>
                </CheckboxContainer>
                <CheckboxContainer
                  onClick={() => setStatement("2")}
                  active={statement === "2"}
                >
                  <CheckBox
                    title={t("JSON_EDITOR")}
                    value={statement === "2"}
                    onChange={() => setStatement("2")}
                  />
                  <InfoText>{t("JSON_EDITOR_INFO")}</InfoText>
                </CheckboxContainer>
              </CheckboxWrapper>
            </StepContainer> */}
            {/* <StepContainer>
              <Typography variant="h3">{t("SECURITY_STATEMENT")}</Typography>
              <CheckboxWrapper>
                <CheckboxContainer
                  onClick={() => setStatement("1")}
                  active={statement === "1"}
                >
                  <CheckBox
                    title={t("MAINTAIN_ACCESS_TO_BUCKET")}
                    value={statement === "1"}
                    onChange={() => setStatement("1")}
                  />
                  <InfoText>
                    {t("MAINTAIN_ACCESS_TO_BUCKET_DESCRIPTION")}
                  </InfoText>
                </CheckboxContainer>
              </CheckboxWrapper>
            </StepContainer> */}
            {/* <StepContainer>
              <Typography variant="h3">{t("CUSTOM_STATEMENT")}</Typography>
              <InfoContainer
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  alignItems: "flex-start",
                }}
              >
                <Typography variant="h3">{t("STATEMENT_ID")}</Typography>
                <Input
                  fieldName="statement_id"
                  title={""}
                  placeholder={t("ENTER_UNIQUE_STATEMENT_ID")}
                  customStyles={{ width: "545px" }}
                  {...formikProps}
                />
                <Typography variant="h3">{t("EFFECT")}</Typography>
                <Typography
                  customStyles={{ color: theme.grey[600] }}
                  variant="small"
                >
                  {t("EFFECT_DESCRIPTION")}
                </Typography>
                <Link linkText={t("COMBINING_IAM_AND_BUCKET_POLICIES")} />
                <Select
                  data={[]}
                  title={t("PRINCIPALS")}
                  selectedValue={""}
                  onChange={() => {}}
                  customStyles={{ width: "545px" }}
                  required
                />
                <Select
                  data={[]}
                  title={t("ACTIONS")}
                  selectedValue={""}
                  onChange={() => {}}
                  customStyles={{ width: "545px" }}
                  required
                />
                <Typography variant="h3">{t("EFFECT")}</Typography>
                <Typography
                  customStyles={{ color: theme.grey[600] }}
                  variant="small"
                >
                  {t("EFFECT_DESCRIPTION")}
                </Typography>
                <Button
                  variant="stroke"
                  size="display"
                  customStyles={{ marginRight: "10px" }}
                  onClick={addNewItem}
                  title={`+ ${t("ADD_CONDITION")}`}
                  loading={loading}
                  loadingComplete={loadingComplete}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "14px",
                  }}
                >
                  {conditionsArr?.map((item: any, index: any) => (
                    <DarkForm>
                      <Select
                        toolTip={false}
                        required
                        data={[]}
                        selectedValue={item.condition}
                        // onChange={() => {}}
                        title={t("CONDITION")}
                        customStyles={{ width: "241px", flex: "auto 1" }}
                        fieldName={`conditions[${index}].condition`}
                        onChange={(e: any) =>
                          handleInputChange(index, "condition", e)
                        }
                      />
                      <Select
                        toolTip={false}
                        required
                        data={[]}
                        selectedValue={item.key}
                        // onChange={() => {}}
                        title={t("KEY")}
                        customStyles={{ width: "241px", flex: "auto 1" }}
                        fieldName={`conditions[${index}].key`}
                        onChange={(e: any) =>
                          handleInputChange(index, "key", e)
                        }
                      />
                      <Input
                        fieldName={`conditions[${index}].value`}
                        required
                        toolTip={false}
                        title={t("VALUE")}
                        placeholder=""
                        value={item.address}
                        customStyles={{ width: "241px", flex: "auto 1" }}
                        {...formikProps}
                        handleChange={(e: any) =>
                          handleInputChange(index, "value", e.target.value)
                        }
                      />
                      <Button
                        onClick={() => removeItem(index)}
                        variant="icon"
                        size="display"
                        // disabled
                        customStyles={{ marginTop: "24px" }}
                      >
                        <img src={icons.DeleteIcon} />
                      </Button>
                    </DarkForm>
                  ))}
                </div>
              </InfoContainer>
            </StepContainer> */}
            <StepContainer>
              <InfoText>{t("JSON_EDITOR_DESCRIPTION")}</InfoText>
              {/* <JSONInput
                // viewOnly={statement === "1"}
                id="policy_editor"
                placeholder = {jsonState}
                // colors= { darktheme }
                // onChange={setJsonState}
                locale={locale}
                height="550px"
                width="545px"
              /> */}
              <JsonEditor
                data={ jsonState }
                setData={ setJsonState }
              />
            </StepContainer>
          </StepsWrapper>
          <div style={{ display: "flex", marginTop: "40px" }}>
            <Button
              variant="primary"
              size="display"
              customStyles={{ marginRight: "10px" }}
              onClick={handleSubmit}
              title={`+ ${t("CREATE")}`}
              loading={loading}
              loadingComplete={loadingComplete}
            />
            <Button
              variant="stroke"
              size="display"
              // customStyles={{width: '81px'}}
              onClick={() => navigate("/floating-ips")}
              title={t("CANCEL")}
            />
          </div>
        </Container>
      </BodyContainer>
    </CreateContainer>
  );
};

export default CreateBucket;
