import React, {FC, useEffect, memo, useRef, useState, useMemo} from "react";
import { flushSync } from 'react-dom';
import {
  Container,
  BodyTitle,
  RowWrapper,
  TableCustom,
  Tr,
} from "./styles";
import { useTranslation } from "react-i18next";
import Select from "../select";
import Pages from "../pagination";
import TableColumnItem from "./column";
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';
import styled from "styled-components";
import TableRowItem from "./row";
import { randomNameGenerator } from "../../utils/create-random-name";

type TableProps = {
  columns:Column[],
  rows:any[],
  hideCheckbox?:boolean,
  hideOther?:boolean,
  isNotifications?:boolean,
  settings?:any,
  limitedItems?:any,
  paginationData?:any,
  pages?:any,
  updateData?:(params?:any) => void,
  trigger?:any,
  allChecked?:any,
  setAllChecked?:any,
  checked?:any,
  setChecked?:any,
  loading?:boolean,
  hasDroppedRow?:boolean,
  withoutHeader?:boolean,

  customSetPage?:any,
  customSetPageSize?:any,
  customPageSize?:any,
}


type Column = {
  title: string,
  dataIndex?:string,
  key: string,
  width?: number,
  hideCheckbox?:boolean,
  withBackground?:boolean,
  
}

const Table:FC<TableProps> = (props) => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [ordering, setOrdering] = useQueryParam('ordering', StringParam);
  const [pageSize, setPageSize] = useQueryParam('page_size', StringParam)
  const [hover, setHover] = useState<boolean>(false)
  
  const [arrCount, setArrCount] = useState<any[]>([
    {value:10, label:10},
    {value:20, label:20},
    {value:30, label:30},
    {value:50, label:50},
    {value:100, label:100},
  ])
  const [currentPage, setCurrentPage] = useState<any>(1)
  const [t] = useTranslation()
  const [dropNodes, setDropNodes] = useState<boolean>(false)
  
  const {
    columns,
    rows,
    hideOther,
    hideCheckbox,
    isNotifications,
    settings,
    limitedItems,
    paginationData,
    updateData,
    allChecked,
    setAllChecked,
    checked,
    setChecked,
    loading,
    hasDroppedRow,
    withoutHeader,
    customSetPage,
    customSetPageSize,
    customPageSize,
  } = props
  const [showItems, setShowItems] = useState<any>(customPageSize || pageSize)

  // useEffect(() => {
  //   let params:any = {
  //     sort:sort,
  //     pageSize:showItems
  //   }
  //   if(updateData) {
  //     //@ts-ignore
  //     updateData(params)
  //   }
  // }, [currentPage, sort, showItems, trigger])
  // useEffect(() => {
  //   if(paginationData?.count > 1){
  //     let arr = []
  //     for(let i = 10; i<=paginationData.count; i+=10){
  //       arr.push({value:i, label:i})
  //       setArrCount(arr)
  //     }
  //   }
  // },[paginationData])
  // if(true)return (
  //   <DataTable
  //     columns={columns}
  //     data={data}
  //   />
  // )
  return (
    <div>
      <TableCustom>
        {!withoutHeader &&
          <TableColumnItem
            hover={hover}
            setHover={setHover}
            columns={columns}
            rows={rows}
            hideCheckbox={hideCheckbox}
            // withBackground={column.withBackground}
            isNotifications={isNotifications}
            settings={settings}
            limitedItems={limitedItems}
            hideOther={hideOther}
            allChecked={allChecked}
            setAllChecked={setAllChecked}
            checked={checked}
            setChecked={setChecked}
          />
        }
        {loading ? 
          null
        :
          <>
            <tbody >
              {rows.filter((itss:any, ind:any) => dropNodes ? true : true).map((items:any, index:any) => {
              return(
                <>
                <Tr key={items.id}>
                  {columns.map((column:any) => (
                    <PreRowItem
                      hasDroppedRow={hasDroppedRow}
                      item={items}
                      dropNodes={dropNodes}
                      setDropNodes={setDropNodes}
                      index={index}
                      column={column}
                      setHover={setHover}
                      hover={hover}
                      hideCheckbox={hideCheckbox}
                      isNotifications={isNotifications}
                      settings={settings}
                      limitedItems={limitedItems}
                      allChecked={allChecked}
                      checked={checked}
                      setChecked={setChecked}
                    />
                  ))}
                </Tr>
                {rows.find((its:any, ind:any) => dropNodes === its?.id)?.nodes.map((item:any, index:any) => {
                  if(dropNodes === items?.id) return(
                        <Tr key={item.id}>
                          {columns.map((column:any) => (
                            <PreRowItem
                              hasDroppedRow={hasDroppedRow}
                              item={item}
                              dropNodes={dropNodes}
                              setDropNodes={setDropNodes}
                              index={index}
                              column={column}
                              setHover={setHover}
                              hover={hover}
                              hideCheckbox={hideCheckbox}
                              isNotifications={isNotifications}
                              settings={settings}
                              disableSettings={true}
                              limitedItems={limitedItems}
                              allChecked={allChecked}
                              checked={checked}
                              setChecked={setChecked}
                            />
                          ))}
                        </Tr>
                      )})
                    }
                </>
                

              )})}
              
            </tbody>
            
          </>
        }

      </TableCustom>
      {hideOther || paginationData?.count <= 10  ? null :
        // paginationData?.next ||  paginationData?.previous ?
        <RowWrapper>
          <div style={{display:'flex', alignItems:'center'}}>
            <BodyTitle style={{marginRight:'7px'}}>
              {t("SHOW_ITEMS")}
            </BodyTitle>
            <div style={{width:'46px'}}>
              <Select
                isSmall
                data={arrCount}
                selectedValue={+showItems || arrCount?.[0]?.value}
                title=''
                onChange={(count) => {
                  if(customSetPage){
                    customSetPage(1)
                  } else {
                    setPage(1)
                  }
                  if(customSetPageSize){
                    customSetPageSize(count)
                    setShowItems(count)
                  } else {
                    setPageSize(count)
                    setShowItems(count)
                  }
            
                }}
              />
            </div>
            <BodyTitle style={{marginLeft:'7px'}}>
              {t("OF")} {paginationData?.count}
            </BodyTitle>
          </div>
          <Pages
            changePage={(page) => {
              setCurrentPage(page)
              
              if(updateData) {
                //@ts-ignore
                updateData({
                  sort:ordering,
                  pageSize:showItems,
                  page:currentPage
                })
              }
            }}
            customSetPage={customSetPage}
            currentPage={+paginationData?.pageNo || 1}
            totalCount={paginationData?.count || 10}
            pageSize={showItems || 10}
            siblingCount={1}
          />
        </RowWrapper>
        // : null
      }
    </div>
  )
}
export default Table

const PreRowItem:FC<any> = (props) => {
  const {
    hasDroppedRow,
    item,
    index,
    column,
    setHover,
    hover,
    hideCheckbox,
    isNotifications,
    settings,
    limitedItems,
    allChecked,
    checked,
    setChecked,
    dropNodes,
setDropNodes,
disableSettings=false
  } = props;
  const [options, setOptions] = useState<boolean>(false)
  const [drop, setDrop] = useState<boolean>(false)
  return(
    <TableRowItem
      dropNodes={dropNodes}
      setDropNodes={setDropNodes}
      hasDroppedRow={hasDroppedRow}
      // key={randomNameGenerator({name:column.key, findArr:[]})}
      key={item?.id || item?.name || item?.title}
      item={item[column.key]}
      itemToEdit={item}
      navigateTo={item.navigate}
      isRead={item.isRead}
      darkBack={index % 2 === 0}
      withBack={column.withBackground}
      testId={column.key}
      first={column.key === 'floating_ip_address' || column.key === 'direction' || column.key === 'name' || column.key === 'id' || column.key === 'port' && index === 0}
      more={column.key === 'props' && !disableSettings}
      eye={column.key === 'eye'}
      generated={column.key === 'generated'}
      setHover={setHover}
      index={index}
      hover={hover === index}
      hideCheckbox={hideCheckbox}
      isNotifications={isNotifications}
      settings={settings}
      limitedItems={limitedItems}
      // key={item.id}
      allChecked={allChecked}
      checked={checked}
      setChecked={setChecked}
      options={options}
      drop={drop}
      setDrop={setDrop}
      setOptions={setOptions}
    />
  )}